import React from 'react'
import dotarrow from './assets/images/dot-arrow.svg'
import tick from './assets/images/tick.png'
import { Button, Col, Form, FormFeedback, Input, Label, Row, Spinner } from 'reactstrap'
import { Tooltip } from 'react-tooltip'
// import * as Yup from "yup";
import { useFormik } from 'formik'
import { contactUs } from './helper/endPoint'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import { useFormik } from 'formik';
import * as Yup from 'yup';  // Ensure Yup is imported
import axios from 'axios';
const Contactform = () => {
  const phoneNumberRules = /^[1-9]\d{9}$/;
  const emailRules = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      username: "",
      email: "",
      mobileNumber: "",
      lastname: "",
      textarea: ""
    },
    validationSchema: Yup.object({
      username: Yup.string().required("Name is required").max(30, "Name must not be more than 16 characters"),
      email: Yup.string().email("Please enter a valid email address").matches(emailRules, { message: "  Invalid email" }).required("Email Address is required"),
      mobileNumber: Yup.string().matches(phoneNumberRules, { message: "Contact Number is Invalid" }).required("Contact Number is required"),
      lastname: Yup.string().required('Last Name is required'),
    }),
    onSubmit: async (value) => {
      try {
        let data = {
          firstName: value.username,
          lastName: value.lastname,
          email: value.email,
          phoneNumber: value.mobileNumber,
          message: value.textarea
        };
        const response = await axios.post(`${process.env.REACT_APP_API_KEY}${contactUs}`, data);
        const result = response?.data.message
        console.log(result, "response");

        toast.success(result, { autoClose: 1000 })
      } catch (error) {
        console.error("Error occurred:", error);
      }
    }
  });

  return (
    <>
      <ToastContainer />
      <div>

        {/* contact form start*/}
        <section id="Contact">
          <div className="sn-shap-elements">
            <img src={dotarrow} alt='Arrow right' className="sn-shap-upDown" />
          </div>
          <div className="container">

            <div className="row">
              <div className="col-xxl-10 col-xl-10 col-lg-12 col-md-12 col-sm-12 mx-auto">
                <div className="sn-heading-title text-center">
                  <h2>Got <span>Questions? We've</span> Got Answers. </h2>
                </div>
              </div>
            </div>

            <div className="row sn-contact-form-main justify-content-center align-items-center">
              <div className="col-md-5 col-sm-12">
                <div className="sn-form-bg-contact snformcircleFOrm">
                  <div className="sn-form-bg-left">
                    <h3>Contact Information</h3>
                    <p>Fill up the form and our Team will get back to you within 24 hours.</p>
                  </div>
                  <div className="sn-contactlist-main">
                    <ul>
                      <li>
                        <img src={tick} alt='Tick' />
                        10 AM - 7 PM</li>
                      <li>
                        <img src={tick} alt='Tick' />
                        Monday - Friday</li>
                    </ul>
                  </div>
                  <div className="sn-contact-circle">
                  </div>
                </div>
              </div>



              <div className="col-md-7 col-sm-12">
                <div className="sn-form-div-start">
                  <Form
                    onSubmit={formik.handleSubmit}
                    className="needs-validation">
                    <div className="mb-3">
                      <Row>
                        <Col sm={6}>
                          <Label htmlFor="username" className="form-label"> First Name<span className="text-danger">*</span></Label>
                          <Input
                            data-tooltip-id="my-tooltip-1"
                            name="username"
                            autoComplete="new-password"
                            type="text"
                            className="form-control"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values["username"]}
                            onInput={(e) => {
                              e.target.value = e.target.value.replace(/[^A-Za-z\s]/g, ''); // Remove non-letter and non-space characters
                            }}
                            invalid={formik.touched["username"] && formik.errors["username"]}
                          />
                          {formik.touched["username"] && formik.errors["username"] && (
                            <FormFeedback type="invalid"><div>{formik.errors["username"]}</div></FormFeedback>
                          )}
                          <Tooltip
                            id="my-tooltip-1"
                            place="top"
                            className="custom-tooltip"
                          >
                            {formik.touched.username && formik.errors.username ? formik.errors.username : ""}
                          </Tooltip>
                        </Col>
                        <Col sm={6} className='mb-4'>
                          <Label htmlFor="lastname" className="form-label">Last Name<span className="text-danger">*</span></Label>
                          <Input
                            data-tooltip-id="my-tooltip-2"
                            name="lastname"
                            autoComplete="new-password"
                            type="text"
                            className="form-control"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            onInput={(e) => {
                              e.target.value = e.target.value.replace(/[^A-Za-z\s]/g, ''); // Remove non-letter and non-space characters
                            }}
                            invalid={formik.touched["lastname"] && formik.errors["lastname"]}
                          />
                          {formik.touched["lastname"] && formik.errors["lastname"] && (
                            <FormFeedback type="invalid"><div>{formik.errors["lastname"]}</div></FormFeedback>
                          )}
                          {formik.touched.lastname && formik.errors.lastname && (
                            <FormFeedback type="invalid"></FormFeedback>
                          )}
                          <Tooltip
                            id="my-tooltip-2"
                            place="top"
                            className="custom-tooltip"
                          >
                            {formik.touched.lastname && formik.errors.lastname ? formik.errors.lastname : ""}
                          </Tooltip>
                        </Col>

                        <Col sm={6} className='mb-4'>
                          <Label htmlFor="email" className="form-label">Email Address<span className="text-danger">*</span></Label>
                          <Input
                            name="email"
                            autoComplete="new-password"
                            data-tooltip-id="my-tooltip-3"
                            type="email"
                            placeholder=""
                            className="form-control"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}

                            invalid={formik.touched["email"] && formik.errors["email"]}
                          />
                          {formik.touched["email"] && formik.errors["email"] && (
                            <FormFeedback type="invalid"><div>{formik.errors["email"]}</div></FormFeedback>
                          )}
                          {formik.touched.email && formik.errors.email && (
                            <FormFeedback type="invalid"></FormFeedback>
                          )}
                          <Tooltip
                            id="my-tooltip-3"
                            place="top"
                            className="custom-tooltip"
                          >
                            {formik.touched.email && formik.errors.email ? formik.errors.email : ""}
                          </Tooltip>
                        </Col>
                        <Col sm={6}>
                          <Label htmlFor="contactNumber" className="form-label">Contact Number<span className="text-danger">*</span></Label>
                          <Input
                            name="mobileNumber"
                            autoComplete="new-password"
                            data-tooltip-id="my-tooltip-4"
                            type="tel"
                            placeholder=""
                            className="form-control"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}

                            invalid={formik.touched["mobileNumber"] && formik.errors["mobileNumber"]}
                          />
                          {formik.touched["mobileNumber"] && formik.errors["mobileNumber"] && (
                            <FormFeedback type="invalid"><div>{formik.errors["mobileNumber"]}</div></FormFeedback>
                          )}
                          {formik.touched.mobileNumber && formik.errors.mobileNumber && (
                            <FormFeedback type="invalid"></FormFeedback>
                          )}
                          <Tooltip
                            id="my-tooltip-4"
                            place="top"
                            className="custom-tooltip"
                          >
                            {formik.touched.mobileNumber && formik.errors.mobileNumber ? formik.errors.mobileNumber : ""}
                          </Tooltip>
                        </Col>
                      </Row>
                    </div>

                    <div className="mb-3 sn-contact-textarea-main">
                      <Row>

                        <Col sm={12}>
                          <Label htmlFor="textarea" className="form-label">Message<span className="text-danger">*</span></Label>
                          <Input
                           data-tooltip-id="my-tooltip"
                           name="textarea"
                           type="textarea"
                           className="form-control"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}

                            invalid={formik.touched["textarea"] && formik.errors["textarea"]}
                          />
                          {formik.touched["textarea"] && formik.errors["textarea"] && (
                            <FormFeedback type="invalid"><div>{formik.errors["textarea"]}</div></FormFeedback>
                          )}
                          {formik.touched.textarea && formik.errors.textarea && (
                            <FormFeedback type="invalid"></FormFeedback>
                          )}
                          <Tooltip
                            id="my-tooltip-4"
                            place="top"
                            className="custom-tooltip"
                          >
                            {formik.touched.textarea && formik.errors.textarea ? formik.errors.textarea : ""}
                          </Tooltip>
                        </Col>
                      </Row>
                    </div>


                    <div className="mt-3">
                      <Button type="submit" className="btn btn-primary sn-sendmst-contact">
                        {/* {loading === true ? <Spinner size="sm" className='me-2'> Loading... </Spinner> : null} */}
                        Submit
                      </Button>
                    </div>

                  </Form>

                  {/* <form className="row g-3">
                  <div className="col-md-6">
                    <label htmlFor="name" className="form-label">First Name</label>
                    <input type="text" className="form-control" id="name" required />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="name" className="form-label">Last Name</label>
                    <input type="text" className="form-control" id="name" required />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="inputEmail4" className="form-label">Email</label>
                    <input type="email" className="form-control" id="inputEmail4" required />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="number" className="form-label">Phone Number</label>
                    <input type="tel" className="form-control" maxLength={10} id="number" required />
                  </div>
                  <div className="col-12">
                    <div className="form-floating">
                      <textarea className="form-control" placeholder="Message" id="floatingTextarea2" style={{ height: '140px' }} defaultValue={""} />
                      <label htmlFor="floatingTextarea2">Message</label>
                    </div>
                  </div>
                  <div className="col-12">
                    <button type="submit" className="btn btn-primary sn-sendmst-contact">Send Message
                    </button>
                  </div>
                </form> */}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  )
}

export default Contactform