
 // JavaScript function to scroll to the top of the page

 document.addEventListener('DOMContentLoaded', function() {
    window.onscroll = function() {
        let element = document.getElementById('your-element-id'); // Replace with your element selector
        if (element) {
            element.style.someProperty = 'value'; // Replace with your style change
        } else {
            console.error('Element not found');
        }
    };
});


    
    // slider logo

    //  var swiper = new Swiper(".mySwiper", {
    //       slidesPerView: 1,
    //       spaceBetween: 10,
    //       pagination: {
    //         el: ".swiper-pagination",
    //         clickable: true,
    //       },
    //       breakpoints: {
    //         "@0.00": {
    //           slidesPerView: 2,
    //           spaceBetween: 10,
    //         },
    //         "@0.75": {
    //           slidesPerView: 2,
    //           spaceBetween: 20,
    //         },
    //         "@1.00": {
    //           slidesPerView: 3,
    //           spaceBetween: 40,
    //         },
    //         "@1.50": {
    //           slidesPerView: 5,
    //           spaceBetween: 50,
    //         },
    //       },
    //     });


     // slider testimonial

      // var swiper = new Swiper(".mySwiperClinet", {
      //     slidesPerView: 1,
      //     spaceBetween: 10,
      //     pagination: {
      //       el: ".swiper-pagination",
      //       clickable: true,
      //     },
      //     breakpoints: {
      //       "@0.00": {
      //         slidesPerView: 1,
      //         spaceBetween: 10,
      //       },
      //       "@0.75": {
      //         slidesPerView: 1,
      //         spaceBetween: 25,
      //       },
      //       "@1.00": {
      //         slidesPerView: 2,
      //         spaceBetween: 25,
      //       },
      //       "@1.50": {
      //         slidesPerView: 2,
      //         spaceBetween: 25,
      //       },
      //     },
      //   });

// counter

    // var appsCounterValue = 130;
    // var websitesCounterValue = 280;
    // var clientsCounterValue = 375;
    // var teamCounterValue = 420;

    // // Function to update counters
    // function updateCounters() {
    //     document.getElementById('appsCounter').innerText = appsCounterValue++;
    //     document.getElementById('websitesCounter').innerText = websitesCounterValue++;
    //     document.getElementById('clientsCounter').innerText = clientsCounterValue++;
    //     document.getElementById('teamCounter').innerText = teamCounterValue++;
    // }

    // updateCounters();
    // setInterval(updateCounters, 1000); 

// Sticky Header


//     const header = document.querySelector(".page-header");
// const toggleClass = "is-sticky";

// window.addEventListener("scroll", () => {
//   const currentScroll = window.pageYOffset;
//   if (currentScroll > 150) {
//     header.classList.add(toggleClass);
//   } else {
//     header.classList.remove(toggleClass);
//   }
// });


                                           


//   var swiper = new Swiper(".mySwiper", {
//     slidesPerView: 1,
//     spaceBetween: 10,
//     pagination: {
//       el: ".swiper-pagination",
//       clickable: true,
//     },
//     breakpoints: {
//       640: {
//         slidesPerView: 2,
//         spaceBetween: 20,
//       },
//       768: {
//         slidesPerView: 4,
//         spaceBetween: 40,
//       },
//       1024: {
//         slidesPerView: 5,
//         spaceBetween: 50,
//       },
//     },
//   });

  