import axios from 'axios'
import React from 'react'
import { Button, Col, Form, FormFeedback, Input, Row } from 'reactstrap'
import { useFormik } from 'formik'
import { subscription } from './helper/endPoint'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import { useFormik } from 'formik';
import * as Yup from 'yup';  // Ensure Yup is imported
import { Tooltip } from 'react-tooltip'


const Newsform = () => {
  const emailRules = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  const { handleSubmit, errors, touched, getFieldProps } = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().email("Please enter a valid email address").matches(emailRules, { message: "  Invalid email" }).required("Email Address is required"),
    }),
    onSubmit: async (value) => {
      try {
        let data = {
          email: value.email,
        };
        const response = await axios.post(`${process.env.REACT_APP_API_KEY}${subscription}`, data);
        toast.success("success",{ autoClose: 1000 })
      } catch (error) {
        console.error("Error occurred:", error);
      }
    }
  });


  return (
    <div>
           <ToastContainer />
      {/* news start */}
      <section className="sn-new-section pt-0">
        <div className="container sn-news-sectiondiv">
          <div className="row">
            <div className="col-xxl-8 col-xl-8 col-lg-12 col-md-12 col-sm-12 mx-auto">
              <div className="sn-heading-title text-center">
                <h2>Get the Latest Knowledge <span>Delivered Directly to Your Inbox</span></h2>
                <p>Enter your email address to stay up-to-date on valuable insights and strategies.</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-8 col-xl-8 col-lg-12 col-md-12 col-sm-12 mx-auto">
              <div className="sn-news-form-buttn">
                 
                  <Form
                    onSubmit={handleSubmit}
                    className="needs-validation sn-news-section-main">
                    
                      <label>
                    <div className="sn-news-email">
                      <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" className="bi bi-envelope" viewBox="0 0 16 16">
                        <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1zm13 2.383-4.708 2.825L15 11.105zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741M1 11.105l4.708-2.897L1 5.383z" />
                      </svg>
                    </div>
                  </label>
                          <Input
                            name="email"
                            autoComplete="new-password"
                            data-tooltip-id="my-tooltip-3"
                            type="email"
                            placeholder="Enter email address*"
                            {...getFieldProps('email', { autoComplete: 'off' })}
                            className="form-control"
                            invalid={touched.email && errors.email}
                          />
                          {touched.email && errors.email && (
                            <FormFeedback type="invalid"></FormFeedback>
                          )}
                          <Tooltip
                            id="my-tooltip-3"
                            place="top"
                            className="custom-tooltip-email"
                          >
                            {touched.email && errors.email ? errors.email : ""}
                          </Tooltip>
                        
                      <Button type="submit">
                        {/* {loading === true ? <Spinner size="sm" className='me-2'> Loading... </Spinner> : null} */}
                        Submit
                      </Button>

                  </Form>
                  {/* <button type="submit">Subscribe</button> */}
                {/* </form> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* news end */}
    </div>
  )
}

export default Newsform
