import './App.css';
import '../src/assets/css/bootstrap.min.css'
import '../src/assets/css/fonts.css'
import '../src/assets/css/responsive.css'
import '../src/assets/css/style.css'
// import '../src/assets/css/swiper-bundle.min.css'
import './assets/js/custom'

// import '../src/assets/js/custom.js'
// import '../src/assets/js/bootstrap.bundle.min.js'
// import '../src/assets/js/custom.js'
// import '../src/assets/js/swiper-bundle.min.js'

// import Header from './Header';
// import Footer from './Footer';
// import Banner from './Banner';
// import Layout from './Layout';


import { BrowserRouter as Router, Routes, Route} from 'react-router-dom'; // Import Routes

// import Home from './Home';
import About from './About';

import Home from './Home';
import Privacypolicy from './Privacypolicy';

import Termsconditions from './Termsconditions';
import Needcallmanagement from'./Needcallmanagement';
import Monitoringinsalesproductivity from './Monitoringinsalesproductivity';
import Callrecordingsdrivebetter from './Callrecordingsdrivebetter';
import Refundpolicy from './refundpolicy';




// import Navigation from './Navigation';

function App() {
  return (

    <Router>
    <div>
     
      {/* <Navigation/> */}
      <Routes> {/* Wrap Routes around Route components */}
        <Route exact path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/privacy-policy" element={<Privacypolicy />} />
        <Route path="/terms-conditions" element={<Termsconditions />} />    
        <Route path="/needcallmanagement" element={<Needcallmanagement />} />  
        <Route path="/monitoringinsalesproductivity" element={<Monitoringinsalesproductivity />} />   
        <Route path="/callrecordingsdrivebetter" element={<Callrecordingsdrivebetter />} />   
        <Route path="/refund-policy-cancellation-policy" element={<Refundpolicy />} /> 
      </Routes>
    </div>
  </Router>
    // <div className="App--">
    //   <header className="sn-app-main-page">
    //     <Header />
    //     <Banner />
    //     <Layout />
    //     <Footer />
    //   </header>      
    // </div>
  );
}



export default App;












