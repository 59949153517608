
import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import exampleImage from './assets/images/logo-main-h.png';
// import './Header.css'; // Make sure you have the corresponding CSS file

const Header = () => {
  const headerRef = useRef(null); // Create a ref for the header

  useEffect(() => {
    const header = headerRef.current;
    const toggleClass = 'is-sticky';

    const handleScroll = () => {
      const currentScroll = window.pageYOffset;
      if (currentScroll > 150) {
        header.classList.add(toggleClass);
      } else {
        header.classList.remove(toggleClass);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className='sn-header-main'>
      <div className="ps-main-wrapper-header sticky-top page-header sticky-top" ref={headerRef}>
        <header>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <nav className="navbar navbar-light navbar-expand-md ps-main-nav">
                  <Link to="/">
                    <img src={exampleImage} alt="Logo" />
                  </Link>
                  <button className="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasDarkNavbar">
                    <span className="navbar-toggler-icon" />
                  </button>
                  <div className="offcanvas offcanvas-end text-bg-light" tabIndex={-1} id="offcanvasDarkNavbar" aria-labelledby="offcanvasDarkNavbarLabel">
                    <div className="offcanvas-header">
                      <button type="button" className="btn-close btn-close-black" data-bs-dismiss="offcanvas" aria-label="Close" />
                    </div>
                    <div className="ps-menu offcanvas-body bt-slidebar">
                      <ul className="navbar-nav justify-content-end flex-grow-1 sn-top-meny-navbar">
                        <li>
                          <Link to="/">Home</Link>
                        </li>
                        <li>
                          <a href="/#about">About Us</a>
                        </li>
                        <li>
                          <a href="/#Features">Features</a>
                        </li>
                        <li>
                          <a href="/#Pricing">Pricing</a>
                        </li>
                        <li>
                          <a href="/#Contact">Contact Us</a>
                        </li>
                      </ul>
                      <div className="bs-login sn-login-register-btn-top">
                        <Link to="https://app.salesninjacrm.com/login" target='_blank' className='sn-ninja-header-btn'>Login</Link> 
                        {/* <Link to="http://137.59.52.100/register" target='_blank'> Signup</Link> */}
                      </div>
                    </div>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </header>
      </div>
    </div>
  );
};

export default Header;
