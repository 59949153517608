import React from 'react'
import Header from './Header'
import Footer from './Footer'

const Refundpolicy = () => {
  return (
    <div>
    <Header />
   {/* sn Privacy Policy Hero*/}
   <section className="sn-blog-detail-banner">
      <div className="container">
        <div className="row">
          <div className="col-xxl-8 col-xl-8 col-lg-12 col-md-12 col-sm-12 mx-auto text-center">
            <h1>Refund Policy and Cancellation Policy</h1>
          </div>
        </div>
      </div>
    </section>
    {/* sn Privacy Policy Hero End */}

     {/* Privacy Policy content section */}
     <section className="sn-blog-content-sec">
      <div className="container">
        <div className="row">
          <div className="col-md-12 col-sm-12">
            <div className="sn-main-blog-sec sn-terms-condition-txt-main">
              <p>At Sales Ninja, we are committed to providing high-quality software solutions to our users. However, due to the nature of our product, we do not offer refunds under any circumstances. </p>
              <h6>Key Points: </h6>
              <div className='sn-privacy-policy-list'>
                    <ul>
                     <li><b>No Refunds</b>: Sales Ninja is a digital software product, and once a purchase is completed, it is non-refundable. Please ensure that you have selected the correct subscription plan before completing your purchase. </li>
                     <li>Final Sale: All sales are final, and no refunds will be issued for any reason, including but not limited to dissatisfaction with the software, accidental purchase, or changes in your business needs. </li>
                    </ul>
              </div> 
              <p>By making a purchase on our platform, you agree to this Refund Policy. If you have any questions or concerns before purchasing, please contact us at <a href='mailto:support@redvisionglobal.com' className='text-danger'>support@redvisionglobal.com</a> </p>
             <h6>Cancellation Policy </h6>
             <p>We understand that circumstances may change, and you may need to review your subscription options. Please carefully read our cancellation policy: </p>
             <h6>No Cancellations for Active Subscriptions: </h6>
             <p>Once you subscribe to our services, the subscription is prepaid and cannot be canceled for the duration of the active subscription period. This applies to both current and future subscriptions. Once a subscription is activated, it will remain in effect until the end of the subscription period. No refunds or credits will be provided for partial or unused subscription periods. </p>
             <h6>Automatic Renewal: </h6>
             <p>Unless canceled before the current billing cycle ends, your subscription will automatically renew for the next billing cycle. You are responsible for managing your subscription and ensuring it aligns with your needs. </p>
             <h6>Data Retention Policy Post-Cancellation or Expiry: </h6>
             <p>If your subscription expires and is not renewed, or if you choose not to subscribe after a free trial, your data will be retained for 30 days from the expiration date. After this period, all associated data will be permanently deleted from our systems. </p>
             <p>By using Sales Ninja, you agree to this Cancellation Policy. Please ensure you fully understand this policy before making any purchase. </p>             
             </div>
          </div>
        </div>
      </div>
    </section>
    {/* Terms & Conditions content section end */}

    <Footer />


</div>
  )
}

export default Refundpolicy
