import React from 'react'

import 'swiper/css';
import Featuresection from './Featuresection'
import Appfeature from './Appfeature'
import Testimonial from './Testimonial'
import Blogsection from './Blogsection'
import Newsform from './Newsform'
import Pricing from './Pricing'
import Contactform from './Contactform';

const Layout = () => {
  return (
    <div className='sn-main-page-content'>
      
<Featuresection />
<Appfeature />
<Testimonial />
<Blogsection />
<Newsform />
<Pricing />
<Contactform />
      

  
      



    </div>
  )
}

export default Layout





