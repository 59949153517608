import React from 'react'
import Header from './Header'
import Footer from './Footer'

const Privacypolicy = () => {
  return (
    <div>
      
      <Header />
       {/* sn Privacy Policy Hero*/}
       <section className="sn-blog-detail-banner">
          <div className="container">
            <div className="row">
              <div className="col-xxl-8 col-xl-8 col-lg-12 col-md-12 col-sm-12 mx-auto text-center">
                <h1>Privacy Policy</h1>
              </div>
            </div>
          </div>
        </section>
        {/* sn Privacy Policy Hero End */}
        {/* Privacy Policy content section */}
        <section className="sn-blog-content-sec">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-sm-12">
                <div className="sn-main-blog-sec sn-terms-condition-txt-main">
                  <p>Welcome to Sales Ninja! We respect your privacy and are committed to protecting your personal information. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our application. </p>
                  <h6>Information We Collect </h6>    
                  <p>We may collect the following types of information: </p> 
                  <div className='sn-privacy-policy-list'>
                    <ul>
                      <li><b>Personal Information</b>: Includes company name, email address, contact number, country, industry, and any other information you provide when you register or use our services. </li>
                      <li><b>Usage Data</b>: Information about how you use our app, such as the features you use and the actions you take. </li>
                      <li><b>Device Information</b>: Information about the device you use to access our app, including IP address, browser type, and operating system. </li>
                      <li><b>Call Log Information</b>: Call log information like Call Time, Call Type, Call Duration, From Number, To Number, To Name, Call Note, Recording File. </li>
                    </ul>
                  </div> 
                  <h6>How We Use Your Information </h6> 
                  <p>We use your information to: </p>  
                  <div className='sn-privacy-policy-list'>
                    <ul>
                      <li>Provide, maintain, and improve our services. </li>
                      <li>Communicate with you, including sending updates and promotional messages. </li>
                      <li>Analyze usage and trends to better understand how our services are used. </li>
                      <li>Enhance the security and functionality of our app. </li>
                      <li>Comply with legal obligations. </li>
                    </ul>
                  </div>  
                  <h6>Sharing Your Information </h6>
                  <p>We may share your information in the following situations: </p> 
                  <div className='sn-privacy-policy-list'>
                    <ul>
                      <li>With Your Consent: We may share your information if you give us explicit permission to do so. </li>
                      <li>For Business Operations: With third-party vendors and service providers who assist us in operating our services. </li>
                      <li>Legal Requirements: If required by law, we may disclose your information to comply with legal processes or government requests. </li>
                      <li>Business Transfers: In connection with any merger, sale of company assets, or acquisition, your information may be transferred as part of the transaction. </li>
                    </ul>
                  </div>  
                  <h6>Data Security </h6>   
                  <p>We implement security measures to protect your information from unauthorized access, alteration, disclosure, or destruction. However, no internet transmission is completely secure, and we cannot guarantee absolute security. </p>
                  <h6>Credit/Debit Card Information </h6>
                  <p>6.1. Credit/debit card details and personally identifiable information will NOT be stored, sold, shared, rented, or leased to third parties. </p>
                  <p>6.2. Debit/credit card details are not passed to third parties. </p>
                  <p>6.3. Merchant takes appropriate steps to ensure data privacy and security, including through various hardware and software methodologies. However, the merchant cannot guarantee the security of any information that is disclosed online. </p>
                  <h6>Third-Party Links </h6>
                  <p>The merchant is not responsible for the privacy policies of websites to which it links. If you provide any information to such third parties, different rules regarding the collection and use of your personal information may apply. You should contact these entities directly if you have any questions about their use of the information they collect. </p>
                  <h6>8. Policy Updates </h6>
                  <p>The Website Policies and Terms & Conditions may be changed or updated occasionally to meet the requirements and standards. Therefore, customers are encouraged to frequently visit these sections to be updated about the changes on the website. Modifications will be effective on the day they are posted. </p>
                  <h6>Cookie Policy </h6>
                  <p>Sales Ninja uses cookies to enhance user experience, analyze trends, and gather information about user interactions with our application. Users can manage cookie preferences through their browser settings. The Cookie Policy informs users about the use of cookies, including the types used and their purposes. </p>
                  <h6>Data Retention </h6>
                  <p>We retain your information for as long as necessary to fulfill the purposes outlined in this policy unless a longer retention period is required or permitted by law. </p>
                  <h6>International Data Transfers </h6>
                  <p>Your information may be transferred to, stored, and processed in countries other than your own. We take steps to ensure that your data is treated securely and in accordance with this Privacy Policy. </p>
                  <h6>Grievance Redressal </h6>  
                  <p>For any questions or grievances regarding this privacy policy or the handling of personal information, users can contact our grievance officer at <a href='mailto:support@salesninjacrm.com'>support@salesninjacrm.com</a> </p>                
                 <h6>Children's Privacy </h6>
                 <p>Sales Ninja is not intended for users under the age of 18. If you are under 18, please do not use Sales Ninja. Transactions should be conducted by a legal guardian or parent who has registered as a user. </p>
                 <h6>Contact Us </h6>
                 <p>If you have any questions or concerns about this Privacy Policy, please contact us at: </p>
                 <div className='sn-privacy-policy-list'>
                    <ul>
                      <li><b>Company Name</b>: REDVision FutureTech IT Services Co.  </li>
                      <li><b>Email</b>: support@salesninjacrm.com </li>
                      <li><b>Phone</b>: +97 154 590 3053 </li>

                      <li><b>Office Address</b>: Aspin Commercial Tower – Sheikh Zayed Road, 5th Floor – Dubai </li>
                      <li><b>Country</b>: United Arab Emirates </li>
                    </ul>
                  </div>
                 
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Privacy Policy content section end*/}
      <Footer />
    </div>
  )
}

export default Privacypolicy


