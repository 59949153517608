import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import clientlogo from './assets/images/client-logo.png'
import questionimg from './assets/images/question.png'
import c1 from './assets/images/c1.jpg'
import c2 from './assets/images/c2.jpg'
import clientlogo2 from './assets/images/client-logo-2.png'
import c3 from './assets/images/c3.jpg'

const Testimonial = () => {
  return (
    <div>
        {/* client sec start */}
        <section className="sn-client-sec- pb-0 d-none">
        <div className="container">
          <div className="row">
            <div className="col-xxl-10 col-xl-10 col-lg-12 col-md-12 col-sm-12 mx-auto">
              <div className="sn-heading-title text-center">
                <h2>Don’t just take our word for it, hear why <span>our clients love</span> using Sales Ninja.</h2>
                <p>Client testimonials highlight how our CRM streamlines workflows, and boosts business growth. 
                </p>
              </div>
            </div>
          </div>

          <div className='row'>
          <Swiper
      className='mySwiper'
      spaceBetween={50}
      slidesPerView={2}
      onSlideChange={() => console.log('slide change')}
      onSwiper={(swiper) => console.log(swiper)}
      loop={true}
      autoplay={{
        delay: 500,
        disableOnInteraction: false,
      }}
      pagination={{ clickable: true }}
      breakpoints={{
        // when window width is >= 320px
        320: {
          slidesPerView: 1,
          spaceBetween: 20,
        },
        // when window width is >= 480px
        480: {
          slidesPerView: 1,
          spaceBetween: 30,
        },
        // when window width is >= 640px
        640: {
          slidesPerView: 1,
          spaceBetween: 40,
        },
        // when window width is >= 1024px
        1024: {
          slidesPerView: 2,
          spaceBetween: 30,
        },
      }}
    >
      <SwiperSlide>
        <div className="swiper-slide">
          <div className="sn-client-main-box">
            <div className="sn-client-logo-sec">
              <img src={clientlogo} alt='clientlogo' className='sn-clint-logom' />
              <img src={questionimg} alt='Question' className='sn-question-img' />
            </div>
            <div className="sn-client-para">
              <p>With call recordings on Sales Ninja, I can hear what's working and what's not! My team feels more confident, and our customers appreciate our service.</p>
            </div>
            <div className="sn-clinet-details">
              <div>
                <img src={c1} alt='client img' />
              </div>
              <div className="sn-client-name">
                <h4>Dane Whitney</h4>
                <small>Creative and Identity Partner</small>
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="swiper-slide">
          <div className="sn-client-main-box">
            <div className="sn-client-logo-sec">
              <img src={clientlogo2} alt='client' className="sn-clint-logom" />
              <img src={questionimg} alt='Question' className='sn-question-img' />
            </div>
            <div className="sn-client-para">
              <p>I used to miss out on so many leads because I had no idea where my calls were coming from. Sales Ninja's call tracking helps target the right people easily.</p>
            </div>
            <div className="sn-clinet-details">
              <div>
                <img src={c2} alt='john doe' />
              </div>
              <div className="sn-client-name">
                <h4>Jean Pierre</h4>
                <small>Creative and Identity Partner</small>
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="swiper-slide">
          <div className="sn-client-main-box">
            <div className="sn-client-logo-sec">
              <img src={clientlogo} alt='clientlogo' className='sn-clint-logom' />
              <img src={questionimg} alt='Question' className='sn-question-img' />
            </div>
            <div className="sn-client-para">
              <p>Assessing calling trends, identifying areas for improvement, and making data-driven decisions through Sales Ninja helped me increase my sales exponentially.</p>
            </div>
            <div className="sn-clinet-details">
              <div>
                <img src={c3} alt='c3' />
              </div>
              <div className="sn-client-name">
                <h4>Ian-William Fountain</h4>
                <small>Creative and Identity Partner</small>
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide>
    </Swiper>

          {/* <Swiper className='mySwiper'
      spaceBetween={50}
      slidesPerView={2}
      onSlideChange={() => console.log('slide change')}
      onSwiper={(swiper) => console.log(swiper)}
      loop={true}
      autoplay={{
          delay: 500,
          disableOnInteraction: false
      }}
      pagination={{ clickable: true }}
    >
      <SwiperSlide>
        <div className="swiper-slide">
                    <div className="sn-client-main-box">
                      <div className="sn-client-logo-sec">
                        <img src={clientlogo} alt='clientlogo' className='sn-clint-logom' />
                        <img src={questionimg} alt='Question' className='sn-question-img' />
                      </div>
                      <div className="sn-client-para">
                        <p>With call recordings on Sales Ninja, I can hear what's working and what's not! My team feels more confident, and our customers appreciate our service.</p>
                      </div>
                      <div className="sn-clinet-details">
                        <div>
                          <img src={c1} alt='client img' />
                        </div>
                        <div className="sn-client-name">
                          <h4>Dane Whitney</h4>
                          <small>Creative and Identity Partner</small>
                        </div>
                      </div>
                    </div>
                  </div>
          </SwiperSlide>
      <SwiperSlide>
      <div className="swiper-slide">
                    <div className="sn-client-main-box">
                      <div className="sn-client-logo-sec">
                        <img src={clientlogo2} alt='client'  className="sn-clint-logom" />
                        <img src={questionimg}  alt='Question' className='sn-question-img' />
                      </div>
                      <div className="sn-client-para">
                        <p>I used to miss out on so many leads because I had no idea where my calls were coming from. Sales Ninja's call tracking helps target the right people easily.</p>
                      </div>
                      <div className="sn-clinet-details">
                        <div>
                         <img src={c2} alt='john doe' />
                        </div>
                        <div className="sn-client-name">
                          <h4>Jean Pierre</h4>
                          <small>Creative and Identity Partner</small>
                        </div>
                      </div>
                    </div>
                  </div>
      </SwiperSlide>
      <SwiperSlide>
      <div className="swiper-slide">
                    <div className="sn-client-main-box">
                      <div className="sn-client-logo-sec">
                      <img src={clientlogo} alt='clientlogo' className='sn-clint-logom' />
                      <img src={questionimg} alt='Question' className='sn-question-img' />
                      </div>
                      <div className="sn-client-para">
                        <p>Assessing calling trends, identifying areas for improvement, and making data-driven decisions through Sales Ninja helped me increase my sales exponentially.</p>
                      </div>
                      <div className="sn-clinet-details">
                        <div>
                        <img src={c3} alt='c3' />
                        </div>
                        <div className="sn-client-name">
                          <h4>Ian-William Fountain</h4>
                          <small>Creative and Identity Partner</small>
                        </div>
                      </div>
                    </div>
                  </div>
      </SwiperSlide>
     
      ...
    </Swiper> */}
          </div>
        </div>
      </section>
      {/* client sec end */}
    </div>
  )
}

export default Testimonial
