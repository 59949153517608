import React from 'react'
import blogimg1 from './assets/images/blog-1.png'
import blogimg2 from './assets/images/blog-2.png'
import blogimg3 from './assets/images/blog-3.png'
import { Link } from 'react-router-dom';



const Blogsection = () => {
  return (
    <div>
      
      {/* blog start */}
      <section className="sn-blog-main">
        <div className="container">
          <div className="row mb-4">
            <div className="col-xxl-10 col-xl-10 col-lg-12 col-md-12 col-sm-12 mx-auto">
              <div className="sn-heading-title text-center">
                <h2>Blogs - <span>Knowledge Bank</span></h2>
                <p>Expert Guidance &amp; Practical Tips to Transform Your Approach For Business Growth. </p>
              </div>
            </div>
          </div> 
          <div className="row mt-4">
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 text-center justify-content-center">
              <div className="sn-blog-main-sec">
              <a href='/Needcallmanagement'>
                  <figure>
                  <img src={blogimg1} alt='Blog' />
                  </figure>
                  <h3>Why Do Businesses Need Call Management in 2024? </h3>
                </a>
                <div className="sn-blog-sale">
                  <h5>Sales</h5>
                  <p>30 January, 2024</p>
                </div>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 text-center justify-content-center">
              <div className="sn-blog-main-sec">
              <a href='/Monitoringinsalesproductivity'>
              <figure> 
                  <img src={blogimg2} alt='Blog' />
                </figure>
                  <h3>The Role of Call Monitoring in Sales Productivity</h3>
                  </a>
                <div className="sn-blog-sale">
                  <h5>Sales</h5>
                  <p>30 January, 2024</p>
                </div>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 text-center justify-content-center">
              <div className="sn-blog-main-sec">
              <a href='/Callrecordingsdrivebetter'>
              <figure>
                  <img src={blogimg3} alt='Blog' />
                   </figure>
                  <h3>How Do Call Recordings Drive Better Business Decisions?</h3>
              </a>
                <div className="sn-blog-sale">
                  <h5>Sales</h5>
                  <p>30 January, 2024</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Blog end */}
      {/* Swipper Start*/}
    </div>
  )
}

export default Blogsection
