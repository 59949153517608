import React from 'react'
import Header from './Header'
import Footer from './Footer'

const Termsconditions = () => {
  return (
    <div>
        <Header />
       {/* sn Privacy Policy Hero*/}
       <section className="sn-blog-detail-banner">
          <div className="container">
            <div className="row">
              <div className="col-xxl-8 col-xl-8 col-lg-12 col-md-12 col-sm-12 mx-auto text-center">
                <h1>Terms & Services</h1>
              </div>
            </div>
          </div>
        </section>
        {/* sn Privacy Policy Hero End */}

         {/* Privacy Policy content section */}
         <section className="sn-blog-content-sec">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-sm-12">
                <div className="sn-main-blog-sec sn-terms-condition-txt-main">
                  <h6>1. Acceptance of Terms </h6>
                  <p>By accessing and using the Sales Ninja Application, you agree to comply with and be bound by these Terms and Conditions. If any part of these terms doesn't align with your preferences, we kindly ask that you refrain from using our application. </p>
                  <h6>2. Description of Service </h6>
                  <p>Sales Ninja provides a call management platform designed to help teams manage their calls, track customer interactions, and improve productivity. Features include call logging, customer relationship management, and performance analytics. </p>
                  <h6>3. Registration and Account Security </h6>
                  <p>3.1. You must register for an account to use Sales Ninja. You agree to provide accurate and complete information during the registration process. </p>
                  <p>3.2. Users are responsible for maintaining the confidentiality of their account credentials and for all activities that occur under their account. </p>
                  <p>3.3. Users agree to notify us immediately of any unauthorized use of their account. </p>
                  <h6>4. User Responsibilities </h6>
                  <p>4.1. You agree to use Sales Ninja only for lawful purposes and in accordance with these Terms and Conditions. </p>
                  <p>4.2. You agree not to use Sales Ninja in any way that could damage, disable, overburden, or impair the application. </p>
                  <p>4.3. You agree not to attempt to gain unauthorized access to any part of the application or its related systems or networks. </p>
                  <p>4.4. Minors under 18 years old cannot register as users or transact on the website. </p>
                  <h6>5. Privacy and Data Protection </h6>
                  <p>5.1. We are committed to protecting your privacy. Our Privacy Policy outlines how we collect, use, and protect your personal information. </p>
                  <p>5.2. By using Sales Ninja, you consent to the collection and use of your data as described in our Privacy Policy. </p>
                  <h6>6. Intellectual Property </h6>
                  <p>6.1. Sales Ninja and its original content, features, and functionality are and will remain the exclusive property of REDVision FutureTech IT Services Co. and its licensors. </p>
                  <p>6.2. You agree not to reproduce, duplicate, copy, sell, resell, or exploit any portion of Sales Ninja without our express written permission. </p>
                  <h6>7. Payment and Fees </h6>
                  <p>7.1. Sales Ninja offers both free trail and paid subscription plans. Details of the subscription plans and fees are provided on our website. </p>
                  <p>7.2. You agree to pay all fees and charges associated with your chosen subscription plan in accordance with the billing terms in effect at the time the fee or charge is due and payable. </p>
                  <p>7.3. The accepted payment methods include Visa, American Express,Rupay, and MasterCard, and the transactions are conducted in the currency displayed at checkout. </p>
                  <p>7.4. The displayed price and currency at the checkout page will be the same as the price and currency printed on the Transaction Receipt. The amount charged to the card will be shown in your card currency. </p>
                  <h6>8. Termination </h6>
                  <p>8.1. We may terminate or suspend your account and access to Sales Ninja immediately, without prior notice or liability, for any reason, including but not limited to a breach of these Terms and Conditions. </p>
                  <p>8.2. Upon termination, your right to use Sales Ninja will cease immediately. If you wish to terminate your account, you may do so by discontinuing use of the application. </p>
                  <h6>9. Limitation of Liability </h6>
                  <p>9.1. To the maximum extent permitted by applicable law, Sales Ninja and its affiliates, officers, employees, agents, partners, and licensors shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses, resulting from: </p>
                  <p>(i) your use or inability to use the application; </p>
                  <p>(iii) any interruption or cessation of transmission to or from our services. </p>
                  <h6>10. Governing Law </h6>
                  <p>These Terms and Conditions shall be governed and construed in accordance with the laws of the United Arab Emirates (UAE) without regard to its conflict of law principles. The governing law is the local law of UAE. </p>
                  <h6>11. Changes to Terms </h6>
                  <p>We reserve the right to modify or replace these Terms and Conditions at any time. If a revision is material, we will provide notice for any new terms taking effect. What constitutes a material change will be determined at our sole discretion. </p>
                  <h6>12. Contact Us </h6>
                  <p>If you have any questions about these Terms and Conditions, please contact us at <a href='mailto:support@salesninjacrm.com' className='text-danger'>support@salesninjacrm.com</a> </p>
                  <h6>13. OFAC-Sanctioned Countries </h6>
                  <p>Trading or services will not be provided to OFAC-sanctioned countries. </p>
                  <h6>14. Transaction Records </h6>
                  <p>Cardholders must retain copies of transaction records and adhere to merchant policies and rules. </p>
                  <h6>15. Ownership and Operation </h6>
                  <p>The website is owned, maintained, and operated by the company name mentioned in the registered trade license. </p>
                 </div>
              </div>
            </div>
          </div>
        </section>
        {/* Terms & Conditions content section end */}

        <Footer />


    </div>
  )
}

export default Termsconditions
