import React from 'react'
import tab1 from './assets/images/tab-1.png'
import tab3 from './assets/images/tab-3.png'
import tab5 from './assets/images/tab-5.png'
import tab6 from './assets/images/tab-6.png'
import tabsimg2 from './assets/images/tabsimg-.png'
const Featuresection = () => {
  return (
    <div>
       {/* sn Feature start */}
       <section className="sn-feature-start" id="about">
        <div className="container">
          <div className="row">
            <div className="col-xxl-10 col-xl-10 col-lg-12 col-md-12 col-sm-12 mx-auto">
              <div className="sn-heading-title text-center">
                <h2>Features That Help <span>Achieve</span> More Your Business</h2>
                <p>Gain actionable intelligence to optimize agent performance
                </p>
              </div>
            </div>   
          </div>
          <div className="row justify-content-center">
            <div className="col-xxl-11 col-xl-11 col-lg-11 col-md-12 ">
              <div className="sn-tab-feature">
                <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                  <li className="nav-item" role="presentation">
                    <button className="nav-link active" id="pills-dashboard-tab" data-bs-toggle="pill" data-bs-target="#pills-dashboard" type="button" role="tab" aria-controls="pills-dashboard" aria-selected="true"><span className="sn-icon-tab"><svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" className="bi bi-speedometer" viewBox="0 0 16 16">
                          <path d="M8 2a.5.5 0 0 1 .5.5V4a.5.5 0 0 1-1 0V2.5A.5.5 0 0 1 8 2M3.732 3.732a.5.5 0 0 1 .707 0l.915.914a.5.5 0 1 1-.708.708l-.914-.915a.5.5 0 0 1 0-.707M2 8a.5.5 0 0 1 .5-.5h1.586a.5.5 0 0 1 0 1H2.5A.5.5 0 0 1 2 8m9.5 0a.5.5 0 0 1 .5-.5h1.5a.5.5 0 0 1 0 1H12a.5.5 0 0 1-.5-.5m.754-4.246a.39.39 0 0 0-.527-.02L7.547 7.31A.91.91 0 1 0 8.85 8.569l3.434-4.297a.39.39 0 0 0-.029-.518z" />
                          <path fillRule="evenodd" d="M6.664 15.889A8 8 0 1 1 9.336.11a8 8 0 0 1-2.672 15.78zm-4.665-4.283A11.95 11.95 0 0 1 8 10c2.186 0 4.236.585 6.001 1.606a7 7 0 1 0-12.002 0" />
                        </svg></span> Intuitive Dashboard</button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button className="nav-link" id="pills-advance-tab" data-bs-toggle="pill" data-bs-target="#pills-advance" type="button" role="tab" aria-controls="pills-advance" aria-selected="false"><span className="sn-icon-tab"><svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" className="bi bi-rocket-fill" viewBox="0 0 16 16">
                          <path d="M10.175 1.991c.81 1.312 1.583 3.43 1.778 6.819l1.5 1.83A2.5 2.5 0 0 1 14 12.202V15.5a.5.5 0 0 1-.9.3l-1.125-1.5c-.166-.222-.42-.4-.752-.57-.214-.108-.414-.192-.627-.282l-.196-.083C9.7 13.793 8.85 14 8 14s-1.7-.207-2.4-.635q-.101.044-.198.084c-.211.089-.411.173-.625.281-.332.17-.586.348-.752.57L2.9 15.8a.5.5 0 0 1-.9-.3v-3.298a2.5 2.5 0 0 1 .548-1.562l.004-.005L4.049 8.81c.197-3.323.969-5.434 1.774-6.756.466-.767.94-1.262 1.31-1.57a3.7 3.7 0 0 1 .601-.41A.55.55 0 0 1 8 0c.101 0 .17.027.25.064q.056.025.145.075c.118.066.277.167.463.315.373.297.85.779 1.317 1.537M9.5 6c0-1.105-.672-2-1.5-2s-1.5.895-1.5 2S7.172 8 8 8s1.5-.895 1.5-2" />
                          <path d="M8 14.5c.5 0 .999-.046 1.479-.139L8.4 15.8a.5.5 0 0 1-.8 0l-1.079-1.439c.48.093.98.139 1.479.139" />
                        </svg></span>Advanced Reporting</button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button className="nav-link" id="pills-agent-tab" data-bs-toggle="pill" data-bs-target="#pills-agent" type="button" role="tab" aria-controls="pills-agent" aria-selected="false"><span className="sn-icon-tab"><svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" className="bi bi-person-gear" viewBox="0 0 16 16">
                          <path d="M11 5a3 3 0 1 1-6 0 3 3 0 0 1 6 0M8 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4m.256 7a4.5 4.5 0 0 1-.229-1.004H3c.001-.246.154-.986.832-1.664C4.484 10.68 5.711 10 8 10q.39 0 .74.025c.226-.341.496-.65.804-.918Q8.844 9.002 8 9c-5 0-6 3-6 4s1 1 1 1zm3.63-4.54c.18-.613 1.048-.613 1.229 0l.043.148a.64.64 0 0 0 .921.382l.136-.074c.561-.306 1.175.308.87.869l-.075.136a.64.64 0 0 0 .382.92l.149.045c.612.18.612 1.048 0 1.229l-.15.043a.64.64 0 0 0-.38.921l.074.136c.305.561-.309 1.175-.87.87l-.136-.075a.64.64 0 0 0-.92.382l-.045.149c-.18.612-1.048.612-1.229 0l-.043-.15a.64.64 0 0 0-.921-.38l-.136.074c-.561.305-1.175-.309-.87-.87l.075-.136a.64.64 0 0 0-.382-.92l-.148-.045c-.613-.18-.613-1.048 0-1.229l.148-.043a.64.64 0 0 0 .382-.921l-.074-.136c-.306-.561.308-1.175.869-.87l.136.075a.64.64 0 0 0 .92-.382zM14 12.5a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0" />
                        </svg></span>Agent Performance</button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button className="nav-link" id="pills-call-tab" data-bs-toggle="pill" data-bs-target="#pills-call" type="button" role="tab" aria-controls="pills-call" aria-selected="false"><span className="sn-icon-tab"><svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" className="bi bi-telephone-minus" viewBox="0 0 16 16">
                          <path fillRule="evenodd" d="M10 3.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5" />
                          <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.6 17.6 0 0 0 4.168 6.608 17.6 17.6 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.68.68 0 0 0-.58-.122l-2.19.547a1.75 1.75 0 0 1-1.657-.459L5.482 8.062a1.75 1.75 0 0 1-.46-1.657l.548-2.19a.68.68 0 0 0-.122-.58zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z" />
                        </svg></span>Call Tracking</button>
                  </li>
                  <li>
                    <button className="nav-link" id="pills-recording-tab" data-bs-toggle="pill" data-bs-target="#pills-recording" type="button" role="tab" aria-controls="pills-recording" aria-selected="false"><span className="sn-icon-tab"><svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" className="bi bi-voicemail" viewBox="0 0 16 16">
                          <path d="M7 8.5A3.5 3.5 0 0 1 5.95 11h4.1a3.5 3.5 0 1 1 2.45 1h-9A3.5 3.5 0 1 1 7 8.5m-6 0a2.5 2.5 0 1 0 5 0 2.5 2.5 0 0 0-5 0m14 0a2.5 2.5 0 1 0-5 0 2.5 2.5 0 0 0 5 0" />
                        </svg></span>Call Recording</button>
                  </li>
                </ul>
              </div>
              <div className="tab-content sn-tabbgmain mt-4" id="pills-tabContent">
                <div className="tab-pane fade show active" id="pills-dashboard" role="tabpanel" aria-labelledby="pills-dashboard-tab">
                  <div className="row">
                    <div className="col-md-6 col-sm-12">
                      <div className="sn-tab-content-maim">
                        <h3>No more data diving! Track your calls, analyze trends, and identify areas for improvement all in one place</h3>
                        <p>Call Management is now simpler than ever. Assess cellular calls and gain valuable data for informed decision-making.</p>
                      </div>
                      <div className="col-md-12 col-sm-12">
                        <div className="row">
                          <div className="col-md-6 col-sm-12">
                            <div className="sntab-content">
                              <h4>Call Tracking</h4>
                              <p>Discover call logs in a particular time frame, to analyse and improve agent performance. </p>
                            </div>
                          </div>
                          <div className="col-md-6 col-sm-12">
                            <div className="sntab-content">
                              <h4>Call Summary </h4>
                              <p>Assess incoming, outgoing, missed and rejected calls to refine your calling strategy. </p>
                            </div>
                          </div>
                          <div className="col-md-6 col-sm-12">
                            <div className="sntab-content">
                              <h4>Agent Performance </h4>
                              <p>Track call duration and unique dials to assess agent efficiency and outreach. </p>
                            </div>
                          </div>
                          <div className="col-md-6 col-sm-12">
                            <div className="sntab-content">
                              <h4>Hourly Analytics</h4>
                              <p>Track call patterns by hour and identify opportunities to improve resource allocation. </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-12">
                      <div className="sn-tabimg-mainleft">
                      <img src={tab1} alt="Tab 1" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tab-pane fade" id="pills-advance" role="tabpanel" aria-labelledby="pills-advance-tab">
                  <div className="row">
                    <div className="col-md-6 col-sm-12">
                      <div className="sn-tab-content-maim">
                        <p>Make smarter decisions with in-depth reporting! See what's working to optimize agent performance. Manage and improve your workflow with comprehensive reports that help you focus on specific areas for more business growth.</p>
                      </div>
                      <div className="col-md-12 col-sm-12">
                        <div className="row">
                          <div className="col-md-6 col-sm-12">
                            <div className="sntab-content">
                              <h4>Call Log Report </h4>
                              <p>Track all incoming &amp; outgoing calls, including date, time, duration, and more.</p>
                            </div>
                          </div>
                          <div className="col-md-6 col-sm-12">
                            <div className="sntab-content">
                              <h4>Employee Report</h4>
                              <p>Analyze individual agent performance by monitoring call volume, call duration, &amp; more. </p>
                            </div>
                          </div>
                          <div className="col-md-6 col-sm-12">
                            <div className="sntab-content">
                              <h4>Client Report </h4>
                              <p>Gain insights into client interactions by reviewing call history, call frequency, &amp; more. </p>
                            </div>
                          </div>
                          <div className="col-md-6 col-sm-12">
                            <div className="sntab-content">
                              <h4>Never-Attended Report </h4>
                              <p>Explore missed calls and identify potential leads that slipped through the cracks. </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-12">
                      <div className="sn-tabimg-mainleft">
                        <img src={tab3} alt='Tab 3' />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tab-pane fade" id="pills-agent" role="tabpanel" aria-labelledby="pills-agent-tab">
                  <div className="row">
                    <div className="col-md-6 col-sm-12">
                      <div className="sn-tab-content-maim">
                        <p>See How Your Team Works! Track, Manage &amp; Improve Their Performance Easily Get a clear picture of your agents' calls and identify areas for improvement to help your team perform at their best.</p>
                      </div>
                      <div className="col-md-12 col-sm-12">
                        <div className="row">
                          <div className="col-md-6 col-sm-12">
                            <div className="sntab-content">
                              <h4>Total Calls </h4>
                              <p>See call volume per agent to identify high performers and balance the workload for maximum efficiency.</p>
                            </div>
                          </div>
                          <div className="col-md-6 col-sm-12">
                            <div className="sntab-content">
                              <h4>Unique Calls </h4>
                              <p>Track interactions with new numbers, highlighting agents who excel at converting leads into engaged customers.</p>
                            </div>
                          </div>
                          <div className="col-md-6 col-sm-12">
                            <div className="sntab-content">
                              <h4>Average Call Duration </h4>
                              <p>See the average time agents spend on calls, helping identify areas to improve efficiency. </p>
                            </div>
                          </div>
                          <div className="col-md-6 col-sm-12">
                            <div className="sntab-content">
                              <h4>Total Call Duration </h4>
                              <p>Track total call time per agent, ensuring they balance lead generation and customer retention. </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-12">
                      <div className="sn-tabimg-mainleft">
                        <img src={tab5} alt='Tab 5' />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tab-pane fade" id="pills-call" role="tabpanel" aria-labelledby="pills-call-tab">
                  <div className="row">
                    <div className="col-md-6 col-sm-12">
                      <div className="sn-tab-content-maim">
                        <h3>Never Miss a Lead Again! Powerful Call Tracking for Peak Performance </h3>
                        <p>Optimize agent effectiveness by analyzing call logs in a timeframe for missed, incoming, outgoing, and rejected calls to identify trends and improve performance.</p>
                      </div>
                      <div className="col-md-12 col-sm-12">
                        <div className="row">
                          <div className="col-md-6 col-sm-12">
                            <div className="sntab-content">
                              <h4>Incoming Calls </h4>
                              <p>See who's calling and when, so you're always ready to answer calls!</p>
                            </div>
                          </div>
                          <div className="col-md-6 col-sm-12">
                            <div className="sntab-content">
                              <h4>Outgoing Calls </h4>
                              <p>Track your team's outreach and see what's working for lead generation. </p>
                            </div>
                          </div>
                          <div className="col-md-6 col-sm-12">
                            <div className="sntab-content">
                              <h4>Missed Calls </h4>
                              <p>Never miss a potential customer again, follow up on every missed call!</p>
                            </div>
                          </div>
                          <div className="col-md-6 col-sm-12">
                            <div className="sntab-content">
                              <h4>Rejected Calls </h4>
                              <p>Analyze patterns of calls that were actively rejected by your agents.</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-12">
                      <div className="sn-tabimg-mainleft">
                        <img src={tab6} alt='Tab 6' />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tab-pane fade" id="pills-recording" role="tabpanel" aria-labelledby="pills-recording-tab">
                  <div className="row">
                    <div className="col-md-6 col-sm-12">
                      <div className="sn-tab-content-maim">
                        <h3>Hear it all, improve it all! Powerful call recording for better performance.</h3>
                        <p>Maintain peak call performance, quality and safeguard your business with our advanced call recording features. </p>
                      </div>
                      <div className="col-md-12 col-sm-12">
                        <div className="row">
                          <div className="col-md-6 col-sm-12">
                            <div className="sntab-content">
                              <h4>Quality Assurance </h4>
                              <p>Review calls to ensure agents are following best practices and delivering exceptional service.</p>
                            </div>
                          </div>
                          <div className="col-md-6 col-sm-12">
                            <div className="sntab-content">
                              <h4>Agent Training </h4>
                              <p>Identify areas for improvement and provide targeted coaching with pre-recorded calls. </p>
                            </div>
                          </div>
                          <div className="col-md-6 col-sm-12">
                            <div className="sntab-content">
                              <h4>Improved Customer Service </h4>
                              <p>Analyze call recordings to understand customer needs and pain points for better service.</p>
                            </div>
                          </div>
                          <div className="col-md-6 col-sm-12">
                            <div className="sntab-content">
                              <h4>Legal Safeguards </h4>
                              <p>Protect yourself from potential disputes with a secure record of all call interactions. </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-12">
                      <div className="sn-tabimg-mainleft">
                        <img src={tabsimg2} alt="tabs img" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* feature end */}
    </div>
  )
}

export default Featuresection
