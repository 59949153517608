import React from 'react'
import star from './assets/images/star.png'
import pricingarow from './assets/images/pricinh-arrow.png'
import diamond from './assets/images/diamond.png'
import callrecording from './assets/images/call-recording.png'
import refreshicon from './assets/images/resfresh.png'

const Pricing = () => {
  return (
    <div>
       {/* pricing start */}
       <section className="sn-pricing-main pt-0" id="Pricing">
        <div className="container">
          <div className="row">
            <div className="col-xxl-8 col-xl-8 col-lg-12 col-md-12 col-sm-12 mx-auto">
              <div className="sn-heading-title text-center">
                <h2>Affordable <span>Pricing Plans</span> For You</h2>
                <p>Compare features and benefits to choose the plan that fits your budget &amp; goals. 
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-10 col-xl-10 col-lg-12 col-md-12 col-sm-12 mx-auto">
              <div className="sn-pricing-main-box-div">
                <div className="row">
                  <div className="col-md-4 col-sm-12 sn-pricing-box-one">
                    <div className="sn-pricng-basic sn-pricing-first-box">
                      <div className="sn-pricing-title-basic">
                        <div className="sn-pricng-plan-main">
                          <img src={star} alt='Start' />
                          <h4 className='mt-2'>Free  Trial </h4></div>
                          {/* <h3>Coming Soon !</h3> */}
                        <h5 className='mt-3' style={{textAlign:'center'}}>AED 0 
                           {/* / <small>Month</small> */}
                        </h5>
                        <h6>&nbsp;</h6>
                        {/* <h6 style={{textAlign:'center'}}><small>₹ 00</small></h6> */}
                        {/* <p>Unlimited users 349 INR per user</p> */}
                      </div>
                      <div className="sn-pricng-list">
                        <ul>
                          <li>
                            <img src={pricingarow} alt='Pricing icon' />
                            <p>Unlimited User for 15 days</p>
                          </li>
                          <li>
                            <img src={pricingarow} alt='pricing icon' />
                            <p>Unlimited Call Tracking</p>
                          </li>
                          <li>
                            <img src={pricingarow} alt='pricing icon' />
                            <p>Unlimited Employee Onboarding</p>
                          </li>
                          <li>
                            <img src={pricingarow} alt='pricing icon' />
                            <p>Team Management</p>
                          </li>
                          <li>
                            <img src={pricingarow} alt='pricong icon' />
                            <p>Basic Reporting</p>
                          </li>
                          <li>
                            <img src={pricingarow} alt='pricing icon' />
                            <p>Intuitive Dashboard</p>
                          </li>
                        </ul>
                      </div>
                      <div className="bs-login sn-pricing-btn-rgister d-flex justify-content-center align-items-center mt-4 mb-4">
                        {/* <a href="javascript:;" className="ps-btn">Register Now</a> */}
                        <a href='https://app.salesninjacrm.com/register' className="ps-btn" target='_blank'>Try it for Free</a>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-12 sn-pricing-second-right">
                    <div className="sn-pricng-basic">
                      <div className="sn-pricing-title-basic">
                        <div className="sn-pricng-plan-main">
                           <img src={diamond} alt='Diamond' />
                          <h4 className='mt-2'>PREMIUM PLAN</h4></div>
                        
                          <h5 className='mt-3' style={{textAlign:'center'}}>AED 24 
                           {/* / <small>Month</small> */}
                        </h5>
                        <h6 style={{textAlign:'center'}}><small>₹ 535 approximately (excl. taxes)</small></h6>
                          
                       {/* <h5 className='mt-3' style={{textAlign:'center'}}>₹ 499  <img src={refreshicon} alt='Refresh icon' className='sn-refreshicon-sns' />AED   23.4.99 </h5>
                       <h6 style={{textAlign:'center'}}><small>+ 2.45% + AED 1.0 (excl. taxes)</small></h6> */}
                        
                      </div>
                      <div className="sn-pricng-list">
                        <ul>
                          <li>
                            <img src={pricingarow} alt='priceing icom' />
                            <p>Call Tracking</p>
                          </li>
                          <li>
                            <img src={pricingarow} alt='priceing icon' />
                            <p>Team Management</p>
                          </li>
                          <li>
                            <img src={pricingarow} alt='priceing icon' />
                            <p>Advanced Reporting</p>
                          </li>
                          <li>
                            <img src={pricingarow} alt='priceing icon' />
                            <p>Intuitive Dashboard</p>
                          </li>
                          <li>
                            <img src={pricingarow} alt='priceing icon' />
                            <p>Call Recording</p>
                          </li>
                          <li>
                            <img src={pricingarow} alt='priceing icon' />
                            <p>Hierarchy Management</p>
                          </li>
                          <li>
                            <img src={pricingarow} alt='priceing icon' />
                            <p>Dedicated Account Manager</p>
                          </li>
                        </ul>
                      </div>
                      <div className="bs-login sn-pricing-btn-rgister d-flex justify-content-center align-items-center mt-4 mb-4">
                       
                        <a href='https://app.salesninjacrm.com/register' target='_blank' className="ps-btn">Register Now</a>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-12 sn-pricing-box-one">
                    <div className="sn-pricng-basic sn-pricing-first-box border-0">
                      <div className="sn-pricing-title-basic">
                        <div className="sn-pricng-plan-main">
                        <img src={callrecording} alt='call Recoring' />
                          <h4 className='mt-3 mb-2'>Available Add-ons</h4></div>
                          <h5 className='mt-3' style={{textAlign:'center'}}> AED 14
                           {/* / <small>Month</small> */}
                        </h5>
                        <h6 style={{textAlign:'center'}}><small>₹ 270 approximately (excl. taxes)</small></h6>
                          {/* <h3>Call Recording Storage</h3> */}
                        {/* <h3>Free / <small>Month</small></h3> */}
                        {/* <p>Call Recording Storage</p> */}
                      </div>
                      <div className="sn-pricng-list">
                        <ul>
                          <li><h5>Call Recording Storage</h5></li>
                          <li>
                            <img src={pricingarow} alt='priceing icon' />
                            <h6>₹270 <small>/GB/month</small></h6>
                          </li>
                          <li>  <img src={pricingarow} alt='priceing icon' />
                            <p>
                              Sales Ninja automatically uploads all call recordings from employees' devices to a secure, cloud-based dashboard. This enables Team Managers to easily access, review, and manage each call recording, ensuring effective oversight and quality control.</p>
                          </li>
                        </ul>
                      </div>
                      <div className="bs-login mt-4 mb-4 sn-pricing-btn-rgister d-flex justify-content-center align-items-center">
                        {/* <a href="javascript:;" className="ps-btn">Register Now</a> */}
                        <a href='https://app.salesninjacrm.com/register' className="ps-btn" target='_blank'>Register Now</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* pricing end */}
    </div>
  )
}

export default Pricing

