import React from 'react'
import feratureicon from './assets/images/ferature-icon-1.webp'
import securedataimg from './assets/images/secure-data.png'
import featuremobile from './assets/images/feature-mobile.png'
import livechat from './assets/images/live-chat-.png'
import support24 from './assets/images/24-support.png'
import modernui1 from './assets/images/modern-ui-1.png'
import checked from './assets/images/checked.webp'

const Appfeature = () => {
  return (
    <div>
       {/* truster client staart */}
       <section className="snmain-client-sec">
        <div className="container">           
          <div className="row" id="Features">
            <div className="col-xxl-10 col-xl-10 col-lg-12 col-md-12 col-sm-12 mx-auto">
              <div className="sn-heading-title text-center">
                <h2>Features That <span>Make The App</span> Different! </h2>
                <p>Revolutionize the way you work with features built for action. 
                </p>
              </div>
            </div>
          </div>
          <div className="row sn-data-feature">
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
              <div className="sn-ninja-main-features">
                <img src={feratureicon} alt='Feature icon' />
                <h4>Interactive Dashboard</h4>
                <p>Visualize data, track your calls, analyze trends, and gain actionable insights in real-time, all in one place with our interactive dashboard.</p>
              </div>
              <div className="sn-ninja-main-features">
                <img src={securedataimg} alt='securedataimg' />
                <h4>Advanced Call Tracking</h4>
                <p>Optimize agent effectiveness by analyzing call logs in a timeframe for missed, incoming, outgoing, and rejected calls to identify trends and improve performance. </p>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
              <div className="sn-ninma-feature-mobile">

               <img src={featuremobile} alt='featureimg' className='sn-image-about-ms' />

              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
              <div className="sn-ninja-main-features-right">

                <img src={livechat} alt='Live chat' />

                <h4>Smart Notes</h4>
                <p>Empower agents to capture valuable call details through extensive note-taking capabilities, &amp; access critical information on every interaction for better customer service.</p>
              </div>
              <div className="sn-ninja-main-features-right">
                <img src={support24} alt='support notification' />
                <h4>Push Notifications</h4>
                <p>Make informed decisions with push notifications and ensure you receive timely updates directly on your device, without interrupting your workflow.</p>
              </div>
            </div>
          </div>
          <div className="row sn-modern-about">
            <div className="col-md-6 col-sm-12">
              <div className="sn-modern-image-sec">
                <img src={modernui1} alt='modern ui 1' />
              </div>
            </div>
            <div className="col-md-6 col-sm-12">
              <div className="sn-modern-ui-description">
                <h3>Track Your <span>Cellular Calls</span> with Ease</h3>
                <p>Sales Ninja enables call tracking for cellular calls via a cloud-based dashboard. Download the app to start tracking calls using your existing cell number. Benefit from precise call data with automatic logging. Stay updated on your call activity history and performance. </p>
                <ul>
                  <li>
                    <div className="sn-modern-list">
                      <img src={checked} alt='checked' />
                       <span>Call Data</span>
                      <p>Access accurate details of your cellular calls for inbound and outbound calls, including all accepted, rejected, and missed calls. </p>
                    </div>
                  </li>
                  <li>
                    <div className="sn-modern-list">
                      <img src={checked} alt='checked' /> <span>Call Duration</span>
                      <p>Monitor the length of each call with our comprehensive duration tracking feature. Eliminate uncertainty regarding call lengths. </p>
                    </div>
                  </li>
                  <li>
                    <div className="sn-modern-list">
                      <img src={checked} alt='checked' /> <span>Call History</span>
                      <p>Download the app to access your call history securely. Ensure that you never lose important call details.</p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* trusted client end */}
    </div>
  )
}

export default Appfeature
