import React from 'react'
import Header from './Header'
import Banner from './Banner'
import Layout from './Layout'
import Footer from './Footer'
import logodate from './assets/images/expired.png'
import { Card } from 'reactstrap'
const Home = () => {
    return (
        <>
            <Header />
            <Banner />
            <Layout />
            <Footer />


           
           {/* <div className='sn-expired-section'>
               
              
               <img src={logodate} alt='date image' />
                <h2>Your subscription has expired.
                </h2>
               
                <div className='sn-expierd-perragraph'>
                <p className='mb-0'>You no longer have access to Sales Ninja.</p>
                <p>To continue using Sales Ninja , you will need to upgrade your plan.</p>
                </div>
                <div className='sn-date-sub-btn-main mt-4'>
                    <a href='javascript:;' className='sn-continue-experied'>Continue</a>
                    <a href='javascript:;' className='sn-subcribe-experied'>Subscribe Now</a>

                </div>
              
        
            </div> */}
          


        </>
    )
}

export default Home


