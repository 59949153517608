import React from 'react'
import bannercall from './assets/images/call-icon.gif'
import bnrmainimg from './assets/images/banner-image-.png'
import videoicon from './assets/images/play-button.webp'

const Banner = () => {
  return (
    <div className='sn-banner-hero'>
      
        {/* sn banner start */}
        <section className="sn-banner-main">
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-md-5 col-sm-12">
              <div className="sn-tagline-platform">
                <small>
                <img src={bannercall} alt="Footer Logo" />
                     No. 1 Platform for Call Managment </small>
                <h1>The Future of Sales is Here: AI-powered CRM for Exponential Growth</h1>
                <p>Seamlessly monitor calls in real-time, track key agent performance metrics, and gain insights that can help you optimize your workflow and boost your team's productivity with our all-in-one call management solution. </p>
                <div className="bs-login">
                  <a href="https://app.salesninjacrm.com/register" target="_blank" className="ps-btn">Free trial</a>
                </div>
                <div className="sn-take-product-bnr">
                  <a href="javascript:;">
                  <img src={videoicon} alt="Banner img" />  Take Product Tour </a>
                </div>
              </div>
            </div>
            <div className="col-md-7 col-sm-12">
              <div className="sn-banner-img-main">
              <img src={bnrmainimg} alt="Banner img" />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* end banner */}

    </div>
  )
}

export default Banner





